<template>
<div class="">
    <div id="app" data-v-app="">
        <random></random>
        <div data-v-309a58d9="" id="wrapper">
            <random></random>
            <random></random>
            <div data-v-2e5b7498="" class="homes">
                <div data-v-7f9c7298="" data-v-2e5b7498="" class="home">
                    <div data-v-7f9c7298="" class="van-nav-bar van-hairline--bottom">
                        <div class="van-nav-bar__content">
                            <div class="van-nav-bar__left van-haptics-feedback"><i
                                @click="$router.push({path: '/'})"
                                    class="van-badge__wrapper van-icon van-icon-arrow-left van-nav-bar__arrow"><!----><!----><!----></i><!---->
                            </div>
                            <div class="van-nav-bar__title van-ellipsis">{{$t('register.register')}}</div>
                            <div class="van-nav-bar__right van-haptics-feedback"><!----></div>
                        </div>
                    </div><img data-v-7f9c7298="" src="../../assets/files/login_AJOIJNHIU0879SADHKN567ASDSHINVASDDSASD7987987ASDSAFCHSFQFF4G.png" width="150"
                               height="150" alt="" class="li_img"><!----><!---->
                </div>
                <random></random>
                <random></random>
                <form data-v-2e5b7498="" class="van-form">
                    <div data-v-2e5b7498="" class="van-cell-group van-cell-group--inset">
                        <random></random>
                        <div data-v-2e5b7498="" class="van-cell van-field"><!---->
                            <div class="van-cell__title van-field__label" style="width: 100px;"><label
                                    id="van-field-27-label" for="van-field-27-input">{{$t('register.invitationCode')}}</label><!----></div>
                            <random></random>
                            <div class="van-cell__value van-field__value">
                                <div class="van-field__body"><input v-model="form.inviteCode" type="text" id="van-field-27-input"
                                        name="invite_code" class="van-field__control" :placeholder="$t('register.invitationCode')"
                                        aria-labelledby="van-field-27-label"><!----><!----><!----></div><!----><!---->
                            </div><!----><!---->
                        </div>
                        <!--手机号-->
                        <div data-v-2e5b7498="" class="van-cell van-field zdy"><!---->
                            <random></random>
                            <div class="van-cell__title van-field__label" style="width: 100px;"><label
                                    id="van-field-28-label" for="van-field-28-input">{{$t('register.phone')}}</label><!----></div>
                            <div class="van-cell__value van-field__value">
                                <div class="van-field__body"><input v-model="form.account" type="tel" inputmode="numeric"
                                        id="van-field-28-input" name="tel" class="van-field__control" :placeholder="$t('register.phone')"
                                        aria-labelledby="van-field-28-label"><!----><!----><!----></div><!----><!---->
                            </div><!----><!---->
                        </div>

                        <random></random>
                        <random></random>

                        <!--密码-->
                        <div data-v-2e5b7498="" class="van-cell van-field"><!---->
                            <div class="van-cell__title van-field__label" style="width: 100px;"><label
                                    id="van-field-29-label" for="van-field-29-input">{{$t('register.password')}}</label><!----></div>
                            <random></random>
                            <div class="van-cell__value van-field__value">
                                <div class="van-field__body">
                                  <input  v-model="form.password" type="password" id="van-field-29-input" name="pwd"
                                        class="van-field__control" :placeholder="$t('register.password')"
                                        aria-labelledby="van-field-29-label"><!----><!----><!----></div><!----><!---->
                            </div><!----><!---->
                        </div>

                        <random></random>
                        <random></random>

                        <!--确认密码-->
                        <div data-v-2e5b7498="" class="van-cell van-field"><!---->
                            <random></random>
                            <div class="van-cell__title van-field__label" style="width: 100px;"><label
                                    id="van-field-30-label" for="van-field-30-input">{{$t('register.confirmPassword')}}</label><!----></div>
                            <random></random>
                            <div class="van-cell__value van-field__value">
                                <div class="van-field__body">
                                  <input v-model="form.confirmPassword" type="password" id="van-field-30-input" name="pwd2"
                                        class="van-field__control" :placeholder="$t('register.confirmPassword')"
                                        aria-labelledby="van-field-30-label"><!----><!----><!----></div><!----><!---->
                            </div><!----><!---->
                        </div>

                      <random></random>
                      <random></random>

                      <!--提现密码-->
                        <div data-v-2e5b7498="" class="van-cell van-field"><!---->
                            <random></random>
                            <div class="van-cell__title van-field__label" style="width: 100px;"><label
                                    id="van-field-31-label" for="van-field-31-input">{{$t('register.withdrawalPassword')}}</label><!----></div>
                           <random></random>
                           <div class="van-cell__value van-field__value">
                                <div class="van-field__body">

                                  <input  type="password" v-model="form.withdrawalPassword" id="van-field-31-input"
                                        name="deposit_pwd" class="van-field__control" :placeholder="$t('register.withdrawalPassword')"
                                        aria-labelledby="van-field-31-label"><!----><!----><!----></div><!----><!---->
                            </div><!----><!---->
                        </div>
                        <random></random>
                    </div>
                    <div data-v-2e5b7498="" class="buttons">
                        <button @click="registerForm" data-v-2e5b7498="" type="button"
                            class="van-button van-button--primary van-button--normal van-button--block">
                            <div class="van-button__content"><!----><span class="van-button__text">{{$t('register.register')}}</span><!---->
                            </div>
                        </button>

                        <random></random>
                        <random></random>

                        <button data-v-2e5b7498="" type="button" @click="login"
                            class="van-button van-button--primary van-button--normal van-button--block">
                            <div class="van-button__content"><!----><span class="van-button__text">{{$t('register.login')}}</span><!---->
                            </div>
                        </button></div>
                </form>
            </div>
        </div>
    </div>
    <random></random>
    <div class="my-message-container"></div>
    <div data-v-app=""></div>
    <div class="van-overlay" style="z-index: 2001; display: none;"><!----></div>
    <div role="dialog" tabindex="0" class="van-popup van-popup--center van-dialog" aria-labelledby="Tips"
        style="z-index: 2001; display: none;">
        <random></random>
        <random></random>
        <div class="van-dialog__header">Tips</div>
        <div class="van-dialog__content">
            <div class="van-dialog__message van-dialog__message--has-title">You need to log in again after logging out.
                Are you sure</div>
        </div>
        <random></random>
        <random></random>
        <div class="van-hairline--top van-dialog__footer"><button type="button"
                class="van-button van-button--default van-button--large van-dialog__cancel">
                <div class="van-button__content"><!----><span class="van-button__text">cancel</span><!----></div>
            </button>
            <random></random>
            <button type="button"
                class="van-button van-button--default van-button--large van-dialog__confirm van-hairline--left">
                <div class="van-button__content"><!----><span class="van-button__text">confirm</span><!----></div>
            </button></div><!---->
    </div>
    <random></random>
    <random></random>
</div>
</template>

<script>

import "../../assets/files/chunk-vendors.2a887a0e.css";
import "../../assets/files/app.026297a5.css";
import "../../assets/files/717.886187b2.css";
import "../../assets/files/index.830c625c.css";
import "../../assets/files/325.5b7de881.css";

import {register} from '../../api/index';
import random from '../../components/random.vue'
import md5 from 'crypto-js/md5';








export default {
  name: 'HelloWorld',
  data () {
    return {
     form: {
        withdrawalPassword: '',
        account: '',
        inviteCode: '',
        password: '',
        confirmPassword: ''
     }
    }
  },
  components: {random},
  methods: {
    setmd5(val) {
      if (val) {
        return md5(val).toString()
      } else {
        return ''
      }
    },
    login() {
        this.$router.push({path: '/'})
    },

    registerForm() {

      if(!this.form.account || !this.form.password || !this.form.confirmPassword || !this.form.withdrawalPassword) {
        this.$message({
          message: this.$t('password.isEmpty'),
          type: 'error'
        })
      } else if ((this.form.password && this.form.password.length < 6) || (this.form.confirmPassword && this.form.confirmPassword.length < 6) || (this.form.withdrawalPassword && this.form.withdrawalPassword.length < 6)) {
        this.$message({
          message: this.$t('password.pwdSix'),
          type: 'error'
        })
      } else if (this.form.password !== this.form.confirmPassword) {
        this.$message({
          message: this.$t('password.same'),
          type: 'error'
        })
      } else {
        const data = {
          password: this.setmd5(this.form.password),
          confirmPassword: this.setmd5(this.form.confirmPassword),
          withdrawalPassword: this.setmd5(this.form.withdrawalPassword),
          account: this.form.account,
          inviteCode: this.form.inviteCode
        }
        register(data).then(res => {
          if (res.data.status == 0) {
            this.$message({
              message: this.$t('all.success'),
              type: 'success'
            });
            this.$router.push({path: '/'})
          } else {
            this.$message({
              message: this.$t('all.error'),
              type: 'error'
            });
          }

        })
      }

    }

  },
  mounted() {

  },

  computed: {

  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
